<template>
  <div style="overflow-x: hidden;background-color: white;position: absolute;left: 0;right: 0;top: 0" class="bg">
    <Navigator/>
    <SliderBanner :slider="slider"/>
    <Step :stepData="stepInfo" style="background: #F4F7FF"/>
    <FuncShow :funInfo="funInfo" style="background: #F4F7FF"/>
    <Footer/>
    <ContactFloat/>
  </div>
</template>

<script>
import Navigator from '../widgets/navigator'
import Footer from '../widgets/footer'
import SliderBanner from '../widgets/SliderBanner'
import FuncShow from '../widgets/func-show'
import Step from '../widgets/Step'
import ContactFloat from '../widgets/contact-float.vue'
export default {
  components: {
    Navigator,
    FuncShow,
    SliderBanner,
    ContactFloat,
    Step,
    Footer
  },
  data() {
    return {
      stepInfo: {
        title: '璁㈠崟鍒嗕剑鏂规',
        subTitle: '鍒嗕剑鏂规鍙互璁剧疆鏂规妯″紡銆佹柟妗堝悕绉般€佺瓑绾х被鍨嬨€佽繑浣ｇ被鍨嬨€佽繑浣ｈ缃€侀澶栬ˉ璐村拰鍒嗛攢琛ヨ创绛夛紝瀵逛笉鍚屽疄浣撴湁涓嶅悓鐨勬柟妗堣缃紝鏂逛究涓嶅悓闂ㄥ簵鐨勪娇鐢ㄣ€?,
        stepList: [
          {
            normalImg:'',
            choiceImg: '',
            stepTitle: '',
            showImg: require('@/assets/images/index/member-mall/show1.png')
          }
        ]
      },
      slider: {
        image: require('@/assets/images/index/member-mall/phone.png'),
        title: '浼氬憳鍒跺晢鍩?,
        desc: "鏈夋娊鎴愯缃€佸垎浣ｆ柟妗堛€佽鍗曠粨绠楁柟妗堛€佹彁鐜板埗搴﹁缃瓑鍒跺害妯″紡"
      },
      funInfo: {
        title: '',
        list: [
          {
            title: '鎶芥垚璁剧疆',
            img: require('@/assets/images/index/member-mall/img2.png'),
            text: '鐩墠鏅鸿幒绯荤粺鍙娣樺疂鑱旂洘銆佷含涓滆仈鐩熴€佸澶氳繘瀹濄€佸敮鍝佷細銆佸澶氳仈鐩熴€佽€冩媺璧氶挶銆佽嫃瀹佹槗璐€侀タ浜嗕箞銆佺編鍥€佺櫨鏋滃洯銆佸枩鑼躲€佺煶鍖栧姞娌广€佺數褰辩エ銆侀害褰撳姵銆佹槦宸村厠銆佽嚜钀ュ晢鍩庛€佸浜烘嫾鍥€佸尯鍩熶唬鐞嗐€佽偗寰峰熀銆佹眽鍫＄帇銆佹淮婊存墦杞︺€佽姳灏忕尓銆佺憺骞稿挅鍟＄瓑澶氱骞冲彴鐨勬娊鎴愯缃?
          },
          {
            title: '璁㈠崟缁撶畻鏂规',
            img: require('@/assets/images/index/member-mall/img3.png'),
            text: '璁㈠崟缁撶畻鏂规鍙互璁剧疆鏂规鍚嶇О銆佹柟妗堝弬鏁般€佹渶鍚庢洿鏂版椂闂淬€佷娇鐢ㄧ姸鎬佺瓑锛屽涓嶅悓瀹炰綋鏈変笉鍚岀殑鏂规璁剧疆锛屾柟渚夸笉鍚岄棬搴楃殑浣跨敤銆?
          },
          {
            title: '鎻愮幇鍒跺害璁剧疆',
            img: require('@/assets/images/index/member-mall/img4.png'),
            text: '鎻愮幇鍒跺害璁剧疆鍒嗕负鐢ㄦ埛绔拰鍟嗗绔袱绉嶆彁鐜板埗搴﹁缃紝闄や簡鐢ㄦ埛绔湁鏀粯瀹濇彁鐜扮粓绔缃紝鍏朵粬璁剧疆閮戒竴鏍凤紝鍙互璁剧疆鎻愮幇棰戠巼銆佸叧闂彁鐜扮獥鍙ｆ椂鎻愮ず銆佹彁鐜伴檺鍒 '
          }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
